
declare const rxjs: any;
declare const axios: any;
declare const google: any;
declare const HtmlSanitizer: any;

jQuery.event.special.touchstart = {
  setup: function (_, ns: any, handle: any) {
    if (ns.includes("noPreventDefault")) {
      this.addEventListener("touchstart", handle, { passive: false });
    } else {
      this.addEventListener("touchstart", handle, { passive: true });
    }
  }
};
jQuery.event.special.touchmove = {
  setup: function (_, ns: any, handle: any) {
    if (ns.includes("noPreventDefault")) {
      this.addEventListener("touchmove", handle, { passive: false });
    } else {
      this.addEventListener("touchmove", handle, { passive: true });
    }
  }
};

(function (window: any) {

  //################################################
  //  OBSERVABLES
  //################################################

  const createFetchObservable = (path) => {
    return rxjs.fetch.fromFetch(path).pipe(
      rxjs.switchMap(response => {
        if (response.ok) {
          // OK return data
          return response.json();
        } else {
          console.warn(`Unable to load ${path}`)
          return rxjs.of({data:[]});
        }
      }),
      rxjs.pluck('data'),
      rxjs.catchError(err => {
        console.warn(`Failed load request ${path}`);
        console.error(err);
        return rxjs.of([])
      })
    );
  }

  /**
   * `user$` was changed from a Subject to a BehaviorSubject for late subscribers to get
   * the last user value. For example in help.ts to show/hide buttons.
   */
  window.user$ = new rxjs.BehaviorSubject();

  window.settings$ = createFetchObservable('/api/seo/settings').pipe(rxjs.operators.shareReplay());
  window.areas$ = createFetchObservable('/api/seo/areas').pipe(rxjs.operators.shareReplay());
  window.restaurants$ = createFetchObservable('/api/seo/restaurants').pipe(rxjs.operators.shareReplay());
  window.greetings$ = createFetchObservable('/api/seo/greetings').pipe(rxjs.operators.shareReplay());
  window.inserts$ = createFetchObservable('/api/seo/inserts').pipe(rxjs.operators.shareReplay());
  window.foodtags$ = createFetchObservable('/api/seo/foodtags').pipe(rxjs.operators.shareReplay());

  //################################################
  //  OBSERVABLE SUBSCRIPTIONS
  //################################################

  window.user$.subscribe(user => {
    const a = $('#userProfile');
    const navActions = $('#navQuickActions');
    const menu = $('#userMenu');
    const btnLogin = $('#btnLogin');
    const btnReg = $('#btnRegister');
    const elmAddresses = $('#saved_addresses');
    const btnSideNav = $('#menu_btn');
    if (user && user.profile) {
      console.log("UI: Logged IN");
      const { profile } = user;
      const first_name = HtmlSanitizer.SanitizeHtml(profile.first_name);
      const last_name = HtmlSanitizer.SanitizeHtml(profile.last_name);
      const email = HtmlSanitizer.SanitizeHtml(profile.email);
      a.html(`<span class="profile-name">${first_name} ${last_name}</span><span class="profile-email">${email}</span>`);
      menu.show();
      btnLogin.text('Sign Out');
      btnReg.hide();
      navActions.hide();
      btnSideNav.removeClass('drop');
    } else {
      console.log("UI: NOT logged in");
      a.html('');
      menu.hide();
      btnLogin.text('Login');
      btnReg.show();
      navActions.css('display', 'flex');
      btnSideNav.addClass('drop');

      //hide side nav
      btnSideNav.removeClass("active");
      const nav = document.querySelector(".site-nav")
      if (nav) nav.classList.remove("active");
    }
    if (user && user.saved_addresses) {
      const { saved_addresses } = user;
      ['home', 'work', 'other'].forEach(a => $(`#saved_address_${a}`).hide());
      saved_addresses.forEach(address => {
        if (!address.street_name) {
          console.log("Saved address", address.label, "has no street name.")
          return;
        }
        const elm = $(`#saved_address_${address.label}`);
        if (elm.length) {
          elm.show();
          elm.attr("href", `/deliver/${address.latitude}/${address.longitude}/${address.street_name.replace(/ /g, '+')}?label=${address.label}`);
          //elm.click(() => window.location.href = `/deliver/${address.latitude}/${address.longitude}/${address.street_name}`);
        } else {
          console.log("No element with the id", `#saved_address_${address.label}`);
        }
      });
      elmAddresses.show();
    } else {
      elmAddresses.hide();
    }
  });

  //################################################
  //  LOAD CURRENT USER
  //################################################

  const key = 'mrdf_user';
  const jwtkey = `${key}_jwt`;

  window.getMe = () => {
    axios.get('/api/me').then(res => { //THIS CALL DEPENDS ON THE COOKIE BEING SET
      if (!res.data.success || !res.data.data.profile) {
        throw new Error('Auth check failed.');
      }
      try {
        localStorage.setItem(jwtkey, res.headers['x-client-jwt']);
        localStorage.setItem(key, JSON.stringify(res.data.data));
      } catch (error) {
        console.log('User session cannot be saved. localStorage access denied.');
      }
      window[key] = res.data.data;
      window.user$.next(window[key]);
    }).catch(er => {
      window.logout();
    });
  }

  if (window[key]) {
    window.user$.next(window[key]);
  } else {
    let storedUser;
    try {
      storedUser = localStorage.getItem(key);
    } catch (error) {
      console.log('User was not saved. localStorage access denied.');
    }
    let user = null;
    if (storedUser) {
      try {
        user = JSON.parse(storedUser);
      } catch (error) {
        user = null;
      }
    }
    if (user) {
      window[key] = user;
      window.user$.next(user);
    } else {
      window.getMe();
    }
  }

  window.getClientJwt = () => {
    try {
      return localStorage.getItem(jwtkey);
    } catch (error) {
      console.log('User JWT was not saved. localStorage access denied.');
      return null;
    }
  };

  window.getUser = () => {
    let str;
    try {
      str = localStorage.getItem(key);
    } catch (error) {
      console.log('User was not saved. localStorage access denied.');
    }
    return str ? JSON.parse(str) : null;
  };

  window.apiGet = (endpoint) => {
    let jwt;
    try {
      jwt = localStorage.getItem(jwtkey);
    } catch (error) {
      console.log('User JWT was not saved. localStorage access denied.');
    }
    return fetch('/api/' + endpoint, {
      headers: [
        ['x-client-jwt', jwt || '']
      ]
    })
  };

  window.apiPost = (endpoint, data) => {
    let jwt;
    try {
      jwt = localStorage.getItem(jwtkey);
    } catch (error) {
      console.log('User JWT was not saved. localStorage access denied.');
    }
    return fetch('/api/' + endpoint, {
      method: 'POST',
      headers: [
        ['x-client-jwt', jwt || ''],
        ['Content-Type', 'application/json']
      ],
      body: JSON.stringify(data)
    })
  };

  window.logout = () => {
    delete window[key];
    try {
      localStorage.removeItem(key);
      localStorage.removeItem(jwtkey);
    } catch (error) {/* localStorage access denied */ }
    window.user$.next(null);
  };

  //################################################
  //  GET APP SESSION
  //################################################

  const btnNavLogin = $('#btnNavLogin');
  const btnLogin = $('#btnLogin');
  const fLogin = e => {
    // console.log('nav btn clicked');
    e.preventDefault();
    if (window.getUser()) {
      window.requestLogout()
        .catch(er => console.warn("WebApp could not log out."))
        .then(() => window.logout());
    } else {
      window.requestSession()
        .then(() => window.getMe())
        .catch(er => window.location.href = '/login')
    }
  };

  
  btnNavLogin.on('click', fLogin);
  btnLogin.on('click', fLogin);

  const btnNavRegister = $('#btnNavRegister');
  const btnRegister = $('#btnRegister');
  const fRegister = e => {
    e.preventDefault();
    window.requestSession()
      .then(() => {
        window.getMe();
      })
      .catch(er => {
        window.location.href = '/register';
      })
  };
  btnNavRegister.on('click', fRegister);
  btnRegister.on('click', fRegister);

  window.requestSession = () => {
    return new Promise((resolve, reject) => {
      const portal = document.createElement('iframe');
      portal.style.display = "none";
      const onWinMsg = e => {
        if (e.origin !== window.APP_HOST) {
          //console.log('Ignoring message from', e.origin);
          return;
        }
        if (e.data && e.data !== 'failed') {
          let access;
          try {
            access = JSON.parse(e.data);
          } catch (error) {
            reject('Invalid auth from webapp.');
            return
          }
          if (!access || !access.auth) {
            reject('Missing auth from webapp.');
            return
          }
          const auth = access.auth.substring(6);
          //console.log("Received session.", auth);
          fetch('/api/regauth', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ auth })
          })
            .then(res => {
              //console.log("COOKIES SET", document.cookie)
              try {
                localStorage.setItem('auth', auth); //save for calls directly to API
              } catch (error) {
                console.log("User session cannot be saved. localStorage access denied.")
              }
              resolve(null);
            })
            .catch(er => reject(er));
        } else {
          reject(e.data.msg);
        }
        document.body.removeChild(portal); //The iframe only gets one message
        window.removeEventListener('message', onWinMsg);
      }
      window.addEventListener('message', onWinMsg);
      portal.src = '/session';
      document.body.appendChild(portal);
    });
  }
  window.requestLogout = () => {
    return new Promise((resolve, reject) => {
      const portal = document.createElement('iframe');
      portal.style.display = "none";
      const onWinMsg = e => {
        if (e.origin !== window.APP_HOST) {
          //console.log('Ignoring message from', e.origin);
          return;
        }
        if (e.data === 'success') {
          document.cookie = "mrdfood-auth=; expires=Thu, 18 Dec 2013 12:00:00 UTC; path=/";
          console.log("cookie cleared");
          try {
            localStorage.removeItem('auth');
          } catch (error) {/* localStorage access denied */ }
          resolve(null);
        } else {
          reject();
        }
        document.body.removeChild(portal); //The iframe only gets one message
        window.removeEventListener('message', onWinMsg);
      }
      window.addEventListener('message', onWinMsg);
      portal.src = '/logout';
      document.body.appendChild(portal);
    });
  }

  //################################################
  //  GOOGLE ADDRESS SEARCH
  //################################################

  const { fromEvent, from, of } = rxjs;
  const { tap, filter, debounceTime, map, distinctUntilChanged, switchMap, catchError } = rxjs.operators;

  if (typeof (google) === 'undefined') {
    console.error("Google is not loaded. Reloading page.");
    if (confirm("Google support libraries did not load. Refresh the page?")) document.location.reload();
    return; //END IIFE
  }

  const sessionToken = new google.maps.places.AutocompleteSessionToken();
  const placesService = new google.maps.places.PlacesService(document.createElement('div'));
  const autocompleteService = new google.maps.places.AutocompleteService();

  window.setupPlaceSearch = function (inputId, menuId) {

    const searchInput = document.getElementById(inputId) as HTMLInputElement;
    const resultsMenu = document.getElementById(menuId);

    if (!searchInput) {
      return; //the current page does not have a search OR auto complete is done by the SDK
    }

    if (!resultsMenu) {
      const autocompleteService = new google.maps.places.Autocomplete(searchInput, {
        types: ['address'],
        componentRestrictions: { country: 'za' },
      });
      autocompleteService.setFields(['address_components', 'formatted_address', 'geometry', 'place_id']);
      autocompleteService.addListener('place_changed', () => {
        const place = autocompleteService.getPlace();
        if (place && place.geometry) {
          window.autocompletedAddress = place;
          if ('onAutocompletedAddress' in window) window.onAutocompletedAddress(place);
        } else {
          delete window.autocompletedAddress;
          if ('onAutocompletedAddress' in window) window.onAutocompletedAddress(null);
        }
      });
      return;
    }

    let searchResultElms; //array of anchor tags
    let activeResult = -1;

    const promisePredictions = (input) => {
      return new Promise((result, reject) => {
        try {
          autocompleteService.getPlacePredictions({
            input, //user text string input
            types: ['address'],
            componentRestrictions: { country: 'za' },
            sessionToken,
          }, (predictions, status) => {
            if (status !== google.maps.places.PlacesServiceStatus.OK) {
              console.log("GOOGLE PLACES SEARCH NOT OK", status);
              result([]);
            } else {
              result(predictions);
            }
          });
        } catch (error) {
          console.error(error);
          result([]);
        }
      });
    };

    const disectGooglePlaceAddress = (comps: { long_name: string, short_name: string, types: string[] }[]) => {
      let values = [
        { type: 'street_number', name: 'street_number', prop: 'long_name' },
        { type: 'route', name: 'street_name', prop: 'long_name' },
        { type: 'sublocality_level_2', name: 'suburb', prop: 'long_name' },
        { type: 'locality', name: 'city', prop: 'long_name' },
        { type: 'administrative_area_level_1', name: 'province', prop: 'long_name' },
        { type: 'postal_code', name: 'postal_code', prop: 'long_name' },
      ];
      return values.reduce<any>((adr, value) => {
        const comp = comps.find(comp => comp.types.includes(value.type));
        adr[value.name] = comp ? comp[value.prop] : '';
        return adr;
      }, {});
    };

    const gotoPlace = (placeId, description) => {
      return new Promise((resolve, reject) => {
        window.grecaptchaExecute('locationSearch_focus').then(token => {
          //console.log("captcha token for location click", token);
        });
        placesService.getDetails({
          fields: ['address_components', 'formatted_address', 'geometry', 'place_id'],
          placeId,
          sessionToken,
        }, (result, status) => {
          if (status !== 'OK') reject();
          //console.log(result)
          const lat = result.geometry.location.lat();
          const lng = result.geometry.location.lng();
          const address = disectGooglePlaceAddress(result.address_components);
          try {
            //save last search
            const sLastSearches = localStorage.getItem('last-searches');
            let arr: any[] = sLastSearches ? JSON.parse(sLastSearches) : [];
            arr = arr.filter(item => !!item.address); //remove previous save versions
            const existing = arr.find(s => s.placeId === placeId);
            if (!existing) arr.push({ placeId, address, lat, lng, title: result.formatted_address, description, updatedAt: Date.now() });
            else existing.updatedAt = Date.now();
            if (arr.length > 10) arr.shift();
            localStorage.setItem('last-searches', JSON.stringify(arr));
          } catch (er) {
            console.log("Search result could not be saved. localStorage access denied.")
          }

          //REDIRECT
          let queryParams = ['street_number', 'suburb', 'city', 'province', 'postal_code'].map(v => `${v}=${address[v]}`).join('&');
          if (window._restaurantId) queryParams += '&select=' + window._restaurantId;
          window.location.href = `/deliver/${lat.toFixed(5)}/${lng.toFixed(5)}/${encodeURIComponent(address.street_name)}?${queryParams}`;
          //console.log(`SELECTED ADDRESS ${lat}, ${lng} on ${streetname}`);
          resolve(null);
        })
      });
    };

    const inputValue$ = fromEvent(searchInput, 'keyup').pipe(
      filter(e => !~[40, 38, 13].indexOf(e.keyCode)),
      filter(e => e.target.value.length),
      map(e => e.target.value)
    );
    const forcedValue = new rxjs.Subject();
    const searche$ = rxjs.merge(inputValue$, forcedValue).pipe(
      debounceTime(900),
      distinctUntilChanged(),
      switchMap(input => from(promisePredictions(input)))
    );
    const navigationKeys$ = fromEvent(searchInput, 'keyup').pipe(
      filter(e => ~[40, 38, 13].indexOf(e.keyCode)),
      tap(e => e.preventDefault()),
      map(e => e.keyCode)
    );

    const splitAddressDescription = (desc) => {
      let parts = desc.split(', ');
      let half = Math.floor(parts.length / 2);
      return { start: parts.slice(0, half).join(', '), end: parts.slice(half).join(', ') };
    }

    searche$.subscribe(arr => {
      //console.log("res", arr);
      activeResult = -1;

      //remove old results
      let elms = resultsMenu.children;
      for (let i = elms.length - 1; i >= 0; i--) resultsMenu.removeChild(elms[i]);

      if (arr.length) {

        //add new results
        searchResultElms = arr.map(place => {
          let split = splitAddressDescription(('description' in place) ? place.description : place.title);
          let a = document.createElement('a');
          //a.className = ''; //TODO: ???
          //a.href = '#';
          a.onclick = e => gotoPlace(place.place_id, place.description).catch(er => {
            if (er && er.message) console.warn("gotoPlace", er.message);
            else if (er) console.warn("gotoPlace", er);
            console.log("Google cannot resolve this place.", place.place_id, place.description);
          });
          a.innerHTML = `<span class="start">${split.start}</span><span class="end">${split.end}</span>`;
          return a;
        })
        searchResultElms.forEach(elm => resultsMenu.append(elm));

        //activate first result
        activeResult = 0;
        let elm = searchResultElms[activeResult];
        if (elm) elm.classList.add('active');
      } else {
        let msg = document.createElement('div');
        msg.className = 'not-found';
        msg.innerHTML = `Oops, that doesn't seem like a valid address.<br>Are you sure you're entering it correctly `;
        resultsMenu.append(msg);
      }

      //show results menu
      resultsMenu.classList.add('show');
    });

    navigationKeys$.subscribe(c => {
      if (!searchResultElms) return;

      if (c == 40) { // DOWN
        //deactivate previous
        let elm = searchResultElms[activeResult];
        if (elm) elm.classList.remove('active');

        //increment
        if (activeResult + 1 < searchResultElms.length) activeResult++;

        //activate current
        elm = searchResultElms[activeResult];
        if (elm) elm.classList.add('active');

      } else if (c == 38) { // UP
        //deactivate previous
        let elm = searchResultElms[activeResult];
        if (elm) elm.classList.remove('active');

        //decrement
        if (activeResult > 0) activeResult--;

        //activate current
        elm = searchResultElms[activeResult];
        if (elm) elm.classList.add('active');

      } else if (c == 13) { // ENTER
        let elm = searchResultElms[activeResult];
        if (elm) elm.onclick();
      }
    });

    searchInput.addEventListener('click', function (e) {
      e.stopPropagation();
      window.grecaptchaExecute('locationSearch_focus');
      let sLastSearches;
      try {
        sLastSearches = localStorage.getItem('last-searches');
      } catch (error) {
        console.log('Last searches was not saved. localStorage access denied.');
      }
      if (searchInput.value.length) {
        forcedValue.next(searchInput.value);
      } else if (sLastSearches) {
        let arr: any[] = JSON.parse(sLastSearches);
        arr = arr.filter(item => !!item.address); //remove save old-version entries
        arr = arr.sort((a, b) => {
          if (b.updatedAt && a.updatedAt) return b.updatedAt - a.updatedAt;
          if (a.updatedAt) return -1;
          return 0;
        });

        //remove old results
        let elms = resultsMenu.children;
        for (let i = elms.length - 1; i >= 0; i--) resultsMenu.removeChild(elms[i]);

        //add previous chosen results
        searchResultElms = arr.map(srch => {
          console.log('updatedAt', srch.updatedAt)
          let split = splitAddressDescription(('description' in srch) ? srch.description : srch.title);
          let a = document.createElement('a');
          //a.className = ''; //TODO: complete the building/styling of the result links
          a.onclick = e => {
            window.grecaptchaExecute('locationSearch/focus').then(token => {
              //console.log("captcha token for location click", arguments);
            })

            //update search result
            srch.updatedAt = Date.now();
            localStorage.setItem('last-searches', JSON.stringify(arr));

            // relocate window
            let queryParams = ['street_number', 'suburb', 'city', 'province', 'postal_code'].map(v => `${v}=${srch.address[v]}`).join('&');
            if (window._restaurantId) queryParams += '&select=' + window._restaurantId;
            window.location.href = `/deliver/${srch.lat.toFixed(5)}/${srch.lng.toFixed(5)}/${encodeURIComponent(srch.address.street_name)}?${queryParams}`;
            //console.log(`PREVIOUSLY SELECTED ADDRESS ${srch.lat}, ${srch.lng} on ${srch.streetname}`);
          };
          a.innerHTML = `<span class="start">${split.start}</span><span class="end">${split.end}</span>`;
          return a;
        })
        searchResultElms.forEach(elm => resultsMenu.append(elm));
        resultsMenu.classList.add('show');

        //activate first result
        activeResult = 0;
        let elm = searchResultElms[activeResult];
        if (elm) elm.classList.add('active');
      }
    })

    // ADD A WAY OF CLOSING THE MENU
    document.addEventListener("click", function (e) {
      window.grecaptchaExecute('locationSearch/dismiss');
      resultsMenu.classList.remove('show');
    });

  }

  // Cookie banner logic
  const cookieBanner = document.getElementById('cookie-banner') as HTMLDivElement;
  const cookieBtn = document.getElementById('cookieBtn') as HTMLButtonElement;

  try {
    let checkCookieBanner = localStorage.getItem('cookieSeen');

    cookieBtn.addEventListener('click', () => {
      localStorage.setItem('cookieSeen', 'shown');
      cookieBanner?.classList.add('hide');
    });

    setTimeout(() => {
      window.onload = () => {
        if (checkCookieBanner !== 'shown') cookieBanner?.classList.remove('hide');
      }
    }, 0)
  } catch (error) {
    
  }

})(window); //IIFE

document.addEventListener("DOMContentLoaded", e => {

  //################################################
  //  ATTATCH GOOGLE ADDRESS SEARCH
  //################################################

  let _window: any = window as any;
  /**
   * With each use of the template `/partial/page/address_search.handlebars` the parameter 
   * `instance` is passes which determines the HTML element Ids of the search input
   * and results menu. A route may at most have these two searches.
   */
  _window.setupPlaceSearch('addressSearchInput_primary', 'searchResultMenu_primary');
  _window.setupPlaceSearch('addressSearchInput_secondary', 'searchResultMenu_secondary');
  _window.setupPlaceSearch('addressSearchInput_mobile', 'searchResultMenu_mobile');
  _window.setupPlaceSearch('addressSearchInput_simple');

  document.dispatchEvent(new Event('setupCompleted'));

  const elmVer = $('#_ver');
  if (elmVer.length) console.info(elmVer.attr('title'));
});

(window as any).geolocateForSearch = () => {
  let _window: any = window as any;
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(position => {
      var circle = new google.maps.Circle({
        center: {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        },
        radius: position.coords.accuracy
      });
      _window.__autocomplete.setBounds(circle.getBounds());
    });
  }
}

// Hide v2 nudge banner
const mrd_banner = $('#mrd_banner');
const close_button = $('#hidev2Banner');

const hideWebV2ts = parseInt(localStorage.getItem('mrd-web-hideWebV2') ?? '');
const showWebV2 = hideWebV2ts ? Date.now() > hideWebV2ts + (1000 * 60 * 60 * 8) : true;

if (showWebV2) {
  localStorage.setItem('mrd-web-hideWebV2', Date.now().toString());
  mrd_banner.show().addClass('show');
}

close_button.on('click', (e) => {
  localStorage.setItem('mrd-web-hideWebV2', Date.now().toString());
  mrd_banner.remove();
});

